import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { useWindowScroll } from 'react-use';
import LogoImg from '../../images/sendeo-logo.svg';
import * as styles from './Header.module.scss';

const cx = classNames.bind(styles);

interface HeaderProps {
  siteTitle: string;
}

const Header: React.FC<HeaderProps> = ({ siteTitle }) => {
  const { y } = useWindowScroll();
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    if (document?.body) {
      setScroll(document?.body?.scrollTop || document?.documentElement?.scrollTop || 0);
    }
  }, []);

  useEffect(() => {
    setScroll(y);
  }, [y]);

  return (
    <header className={cx('header-wrapper', { scrolled: scroll > 5 })}>
      <div className="container">
        <div className={cx('header')}>
          <h1>
            <Link to="/">
              <img className={cx('logo')} src={LogoImg} alt="Sendeo - Painless Email Marketing" />
            </Link>
          </h1>

          <nav>
            <ul>
              <li>
                <Link to="/changelog/">Changelog</Link>
              </li>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
              <li>
                <a href="https://twitter.com/sendeomail" target="_blank">
                  Twitter
                </a>
              </li>
            </ul>
          </nav>

          <a href="https://app.sendeo.co" target="_blank" className={cx('log-in-link')}>
            Sign In
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
