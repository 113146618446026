import classNames from 'classnames/bind';
import React from 'react';
import styles from './Footer.module.scss';
import { Link } from 'gatsby';

const cx = classNames.bind(styles);

const Footer: React.FC = () => {
  return (
    <footer className={cx('footer')}>
      <div className="container">{new Date().getFullYear()} © Sendeo. All rights reserved.</div>
      <ul>
        <li>
          <Link to="/">Early Access</Link>
        </li>
        <li>
          <Link to="/blog/">Blog</Link>
        </li>
        <li>
          <Link to="/changelog/">Changelog</Link>
        </li>
        <li>
          <a href="https://twitter.com/sendeomail">Twitter</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
